<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7305 1.33325H7.0638C5.9138 1.33325 4.98047 2.26659 4.98047 3.41658V17.5833C4.98047 18.7332 5.9138 19.6666 7.0638 19.6666H13.7305C14.8805 19.6666 15.8138 18.7332 15.8138 17.5833V3.41658C15.8138 2.26659 14.8805 1.33325 13.7305 1.33325ZM10.3971 18.8333C9.70547 18.8333 9.14714 18.2749 9.14714 17.5833C9.14714 16.8916 9.70547 16.3333 10.3971 16.3333C11.0888 16.3333 11.6471 16.8916 11.6471 17.5833C11.6471 18.2749 11.0888 18.8333 10.3971 18.8333ZM14.1471 15.4999H6.64714V3.83325H14.1471V15.4999Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcPhone',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
